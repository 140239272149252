import './main-page.css';
import { Options } from './options.js';
import { Unity, useUnityContext } from "react-unity-webgl";
import Footer from './footer.js';

const MainPage = () => {
  const { unityProvider, loadingProgression, isLoaded } = useUnityContext({
    loaderUrl: "/game/Build/Front_Build.loader.js",
    dataUrl: "/game/Build/Front_Build.data",
    frameworkUrl: "/game/Build/Front_Build.framework.js",
    codeUrl: "/game/Build/Front_Build.wasm",
  });
  
  return (
    <body>
      <div className = "mainContainer">
        <div className = "title">
        <header>
          <img alt="Fantasy Online - Beta " src="FO-server-select-title.png" width="588" height="185"/>
        </header>
          {/* Bottom Thing */}
          <div className="App">
            {!isLoaded && (
              <p>Loading Application... {Math.round(loadingProgression * 100)}% | Press ctrl + F5 if it gets stuck.</p>
              )}
                <div className="App2">
                <Unity
                  unityProvider={unityProvider}
                  style={{
                  width: '100vw', maxWidth: '100%', userSelect: "none", maxHeight: "100%"}}
                  />
                  </div>
            </div>
          </div>
        <div>
          <p id='version'>Version: Beta - 0.9.10</p>
        </div>
        <div>
          <Footer/>
        </div>
      </div>
    </body>
  );
}

export default MainPage;
