import React from 'react';
import './footer.css';

const Footer = () => {
    return(
    <div className = "container">
        <div className="footer">

                <div className ="footerText" >
                </div>
                <div className = "footerText2">
                </div>
                    <h2>
                        Welcome to Fantasy Online!
                    </h2>
                    <br />
                    <p>
                        Fantasy Online WAS a free online Flash-based multiplayer RPG. 
                    </p>
                    <p>
                        Currently I am remaking it. We're in "Open Beta", which means 
                    </p>
                    <p>
                        that you can play, but some things might not work quite right.
                    </p>
                    <br />
                    <p>
                        See you in the game!
                    </p>
                    <br />
                    <p>
                        <a href="https://discord.gg/2FkpD526Qg">Discord</a>
                    </p>
                    <p>
                        <a href="https://reddit.com/r/fantasyonline">Reddit</a>
                    </p>
        </div>
        <span style={{fontSize: "10px", color: "#999"}}>Originally made by © Pixelated Games, LLC. All rights reserved. | Remake by N</span>
    </div>
    );
};

export default Footer;